import { useEffect } from 'react'

import { VWO_BASIC_DATA_EXP } from 'gatsby-env-variables'

import Layout from '@itau-loans-www/shopping/src/layouts/LandingPage'
import analytics from '@mobi/libraries/analytics'
import { useLead } from 'hooks'
import { storage, isClient, getVwoData } from 'utils'

import FAQ from './components/FAQ/index'
import Hero from './components/Hero'
import HiredSolutions from './components/HiredSolutions'
import Hiring from './components/Hiring/index'
import Product from './components/Product/index'
import Solutions from './components/Solutions'
import { SOLUTIONS } from './constants'

const Shopping = () => {
  const { changeLeadData } = useLead()

  useEffect(() => {
    const getVwo = async () => {
      try {
        const vwoData = await getVwoData(VWO_BASIC_DATA_EXP)
        if (vwoData?.variant !== undefined) {
          changeLeadData({ vwoVariant: vwoData?.variant ?? 'A' })
        }
      } catch {
        changeLeadData({ vwoVariant: 'A' })
      }
    }
    if (isClient()) {
      storage.session.clear()
    }
    analytics.track('pageLoad', { currentPage: 'home-lp-shopping' })
    getVwo()
  }, [])

  return (
    <Layout>
      <Hero />
      <Solutions />
      <HiredSolutions />
      {SOLUTIONS.map((solution) => (
        <Product key={solution.title} {...solution} />
      ))}
      <Hiring />
      <FAQ />
    </Layout>
  )
}

export default Shopping
