import { useState } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { http } from '@itau-loans-www/shopping/src/services'
import { useLead } from 'hooks'
import { goTo } from 'utils'

export const useHero = (changeLeadData) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const { leadData } = useLead()

  const onSubmit = ({ cpf }) => {
    setIsButtonLoading(true)
    changeLeadData({ cpf })

    datadogRum.addAction('createLeadLP', {
      cpf
    })

    http.proposal
      .newCreateLead({ cpf })
      .then(() => {
        if (leadData?.vwoVariant !== 'A') {
          goTo('/proposta/nome')
        } else {
          goTo('basic-data')
        }
      })
      .catch(() => setIsButtonLoading(false))
  }

  return {
    onSubmit,
    isButtonLoading
  }
}
