import { IdsIcon, IdsMainButton } from '@ids/react'
import { useMediaQuery } from '@itau-loans-www/shopping/src/hooks'
import { Carousel, CarouselItem } from '@mobi/ds'
import analytics from '@mobi/libraries/analytics'
import { setQueryOnUrl, goTo, storage } from 'utils'

import Img from 'components/Img/index'

import './HiredSolutions.scss'
import { LIST_SOLUTIONS } from './constants'

const HiredSolutions = () => {
  const isDesktop = useMediaQuery('desktop')

  return (
    <section className="lp-hired-solutions">
      <div className="container">
        <h3 className="lp-hired-solutions__sub-title">
          Simule já e descubra sua oferta
        </h3>
        <h2 className="lp-hired-solutions__title">Soluções mais contratadas</h2>
      </div>
      <Carousel
        indentifier="hired-solutions"
        variant="slider"
        bullets={!isDesktop}
        arrows={!isDesktop}
        transition={!isDesktop}
        options={{
          perView: 3,
          focusAt: 0,
          bound: true,
          breakpoints: {
            1023: {
              startAt: 0,
              perView: 1.3
            },
            768: {
              startAt: 0,
              perView: 1.1
            }
          }
        }}
        className="lp-hired-solutions__slider"
      >
        {LIST_SOLUTIONS.map((solution) => (
          <CarouselItem key={solution.title}>
            <div className="lp-hired-solutions__slide">
              <Img
                className="lp-hired-solutions__slide-image"
                file={solution.image}
                alt={solution.alt}
              />
              <div className="lp-hired-solutions__slide-wrapper">
                <span
                  className="lp-hired-solutions__slide-description"
                  aria-label={`${solution?.description}`}
                >
                  {solution.description}
                </span>
                <h2
                  className="lp-hired-solutions__slide-title"
                  aria-label={`${solution?.title}`}
                >
                  {solution.title}
                </h2>
                <ul className="lp-hired-solutions__slide-list">
                  {solution.benefits.map((benefit) => (
                    <li
                      className="lp-hired-solutions__slide-item"
                      key={benefit.text}
                      aria-label={`${benefit?.text}`}
                    >
                      <IdsIcon size="S">{benefit.icon}</IdsIcon>
                      <span className="lp-hired-solutions__slide-benefit">
                        {benefit.text}
                      </span>
                    </li>
                  ))}
                </ul>
                <IdsMainButton
                  full
                  className="lp-hired-solutions__slide-button"
                  onClick={() => {
                    analytics.track('buttonClick', {
                      currentPage: 'home-lp-shopping',
                      detail: solution.track_detail
                    })
                    setQueryOnUrl('utm_source', solution.utm_source)
                    storage.session.setItem('source_url', window.location.href)
                    goTo('basic-data')
                  }}
                  aria-label={`simular ${solution.title} agora`}
                >
                  Simular agora
                </IdsMainButton>
              </div>
            </div>
          </CarouselItem>
        ))}
      </Carousel>
    </section>
  )
}

export default HiredSolutions
